<template>
  <div class="view pa24">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto">
      <el-form-item label="活动类型" prop="killType">
        <el-select v-model="ruleForm.killType" :disabled='$route.query.id ? true : false'>
          <el-option
            v-for="(item, index) in types"
            :key="index"
            :label="item.label"
            :value="item.id"
          >
            {{ item.label }}
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动时长" prop="pickerValue">
        <el-date-picker
          v-model="ruleForm.pickerValue"
          value-format="yyyy-MM-dd"
          align="right"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        />
      </el-form-item>
      <el-form-item label="单账号最多可购买数">
        <el-input-number
          :disabled='$route.query.id ? true : false'
          v-model="ruleForm.maxPurchase"
          :min="1"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="添加秒杀商品" v-if="$route.query.id ? false : true">
        <el-button type="primary" @click="centerDialogVisible = true"
          >+添加商品</el-button
        >
      </el-form-item>
    </el-form>
    <commonTable :tableData="tableData" :total="tableData.length" :paginationVisible='false'>
      <template v-slot:table>
        <el-table-column
          prop="goodsId"
          align="center"
          label="产品ID"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column align="center" prop="goodsTypeName" label="分类" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{  (scope.row[ $route.query.id ?'minPrice':'price'] / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column :prop=" $route.query.id ? 'totalBuyNum':'dealNum'" align="center" label="总销量" />
        <el-table-column align="center" label="发布时间">
          <template slot-scope="scope">
            {{ setGetDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <p class="">选择商品规格</p>
    <commonTable :tableData="specList" :total="specList.length">
      <template v-slot:table>
        <el-table-column
          prop="goodsName"
          align="center"
          label="商品名称"
          show-overflow-tooltip
        />
        <el-table-column prop="typeName" align="center" label="商品类型" />
        <el-table-column align="center" prop="specAttribute" label="商品规格" />
        <el-table-column prop="stockNum" align="center" label="商品库存" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{ (scope.row.price / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="500">
          <template slot-scope="scope">
            秒杀金额
            <el-input
               @change="() => {
                setSpec[scope.$index].killPrice =  setSpec[scope.$index].killPrice < 0.01 ? 0.01 : setSpec[scope.$index].killPrice
              }"
              class="d-inline-block w100"
              v-model="setSpec[scope.$index].killPrice"
            />
            元 活动库存
            <el-input
              class="d-inline-block w100"
               @change="() => {
                setSpec[scope.$index].killNum =  setSpec[scope.$index].killNum < 1 ? 1 : setSpec[scope.$index].killNum.replace(/\D/g,'')
              }"
              v-model="setSpec[scope.$index].killNum"
            />
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
      title="选择商品"
      :visible.sync="centerDialogVisible"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      width="60%"
      center
    >
      <product v-if="centerDialogVisible" ref="product" />
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <div class="mt20 d-flex justify-content-center" v-if="$route.query.id ? false : true">
      <el-button @click="save(0)" type="primary">保存到草稿箱</el-button>
      <el-button @click="save(1)">发布</el-button>
    </div>
    <div class="mt20 d-flex justify-content-center" v-else>
      <el-button type="primary" @click="save(draftType)">保存编辑</el-button>
    </div>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import product from "./product";
import { getDataTimeSec } from "@/utils";
import { addKillGoodsInfo, updateKillGoodsInfo } from "@/api/marketingActivities";
export default {
  data() {
    return {
      ruleForm: {
        killType: "", // 活动类型
        pickerValue: "",
        maxPurchase: "",
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() <= Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      rules: {
        killType: [{ required: true, message: "请选择活动类型" }],
        pickerValue: [{ required: true, message: "请选择活动时长" }],
      },
      types: [
        { label: "限时秒杀", id: 1 },
        { label: "促销秒杀", id: 2 },
      ],
      tableData: [],
      specList: [],
      setSpec: [],
      centerDialogVisible: false,
      draftType:'',
      killNo:''//编号
    };
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  components: {
    commonTable,
    product,
  },
  created(){
    const { id } = this.$route.query;
    if(id){
      this.updateKillGoodsInfo(id);
    }
  },
  methods: {
    determine() {
      const { multipleSelection } = this.$refs.product;
      const arr = [];
      this.setSpec = [];
      this.tableData = [multipleSelection];
      for (let i = 0; i < multipleSelection.goodSpecModelList.length; i++) {
        let type = multipleSelection.goodSpecModelList[i];
        let spec = type
        if ( type.specAttribute && !type.specAttribute2 && !type.specAttribute3 && !type.specAttribute4) {
          spec.typeName = type.specAttribute;
        }

        if ( type.specAttribute && type.specAttribute2 && !type.specAttribute3 && !type.specAttribute4) {
          spec.typeName = type.specAttribute + '-' + type.specAttribute2;
        }

        if ( type.specAttribute && type.specAttribute2 && type.specAttribute3 && !type.specAttribute4) {
          spec.typeName = type.specAttribute + '-' + type.specAttribute2 + '-' + type.specAttribute3;
        }

        if ( type.specAttribute && type.specAttribute2 && type.specAttribute3 && type.specAttribute4) {
          spec.typeName = type.specAttribute + '-' + type.specAttribute2 + '-' + type.specAttribute3 + '-' + type.specAttribute4;
        }
        spec.goodsName = multipleSelection.goodsName;
        spec.stockNum = type.specNum;
        this.setSpec.push({
          killPrice: "",
          killNum: "",
          stockNum: type.specNum,
          price: (type.price/100).toFixed(2),
          specId: type.specId,
        });
        arr.push(spec);
      }
      this.specList = arr;
      this.centerDialogVisible = false;
    },
    /**@method  发布*/
    save(type) {
      if (this.tableData.length == 0) {
        this.$message({
          message: "请先选择商品",
          type: "warning",
        });
        return;
      }
      const checkPrice = this.setSpec.find((item) => item.killPrice <= 0);
      if (checkPrice) {
        this.$message({
          message: "商品秒杀金额不能小于0！",
          type: "warning",
        });
        return;
      }
      console.log(this.setSpec)
      for (let i = 0; i < this.setSpec.length; i++) {
        if (!this.setSpec[i].killPrice || !this.setSpec[i].killNum) {
          this.$message({
            message: "商品规格秒杀金额和秒杀库存不能为空或者零！",
            type: "warning",
          });
          return;
        }
        if (Number(this.setSpec[i].killPrice) > Number(this.setSpec[i].price)) {
          this.$message({
            message: "商品秒杀金额不能大于商品价格！",
            type: "warning",
          });
          return;
        }
        if (Number(this.setSpec[i].killNum) > this.setSpec[i].stockNum) {
          this.$message({
            message: "商品秒杀数量不能大于商品总库存！",
            type: "warning",
          });
          return;
        }
      }
      let temp = this.setSpec.map((item) => {
        return {
          specId: item.specId,
          killPrice: item.killPrice * 100,
          killNum: item.killNum,
        };
      });
      let data = {
        killId: this.tableData[0].killId,
        draftType: type,
        goodSpecRequestList: temp,
        killNo:this.killNo,
        goodsId: this.tableData[0].goodsId,
        startTime: `${this.ruleForm.pickerValue[0]} 00:00:00`,
        endTime:`${ this.ruleForm.pickerValue[1]} 23:59:59`,
        killType: this.ruleForm.killType,
        maxPurchase: this.ruleForm.maxPurchase,
        updateType: this.$route.query?.drafts == '1' ? 1 : 0,
      };

      if(this.$route.query.id) delete data.killNo;
      if(!this.$route.query.id) delete data.killId;
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          addKillGoodsInfo(data)
              .then(() => {
                this.$message({
                  message: type == 1 ? "发布成功" : "保存成功",
                  type: "success",
                });
                setTimeout( () => {
                  this.$router.go(-1);
                }, 1000);
              })
              .catch(err => {
                if(err.code == 201){
                  this.$message.error( err.message );
                }else{
                  this.$message.error(type == 1 ? err.message || "发布失败" : "保存失败");
                }
              });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 获取编辑内容 */
   async updateKillGoodsInfo(id){
     const result = await updateKillGoodsInfo({killId:id},{ showLoading: true });
     const { killType, startTime, endTime, maxPurchase, goodSpecModelList, goodsName, stockNum, killNo, draftType } = result.data;
     this.ruleForm.killType = killType;
     this.ruleForm.pickerValue = [this.setGetDataTimeSec(startTime),this.setGetDataTimeSec(endTime)];
     this.ruleForm.maxPurchase = maxPurchase;
     this.tableData = [result.data];
     this.killNo = killNo;
     this.draftType = draftType;
      const arr = [];
     this.setSpec = [];
     for (let i = 0; i < goodSpecModelList.length; i++) {
       let type = goodSpecModelList[i];
       let spec = type;
       spec.goodsName = goodsName;
       if ( type.specAttribute && !type.specAttribute2 && !type.specAttribute3 && !type.specAttribute4) {
         spec.typeName = type.specAttribute;
       }

       if ( type.specAttribute && type.specAttribute2 && !type.specAttribute3 && !type.specAttribute4) {
         spec.typeName = type.specAttribute + '-' + type.specAttribute2;
       }

       if ( type.specAttribute && type.specAttribute2 && type.specAttribute3 && !type.specAttribute4) {
         spec.typeName = type.specAttribute + '-' + type.specAttribute2 + '-' + type.specAttribute3;
       }

       if ( type.specAttribute && type.specAttribute2 && type.specAttribute3 && type.specAttribute4) {
         spec.typeName = type.specAttribute + '-' + type.specAttribute2 + '-' + type.specAttribute3 + '-' + type.specAttribute4;
       }

       spec.stockNum = type.specNum;
       this.setSpec.push({
         killPrice: (type.killPrice / 100).toFixed(2),
         killNum: type.killNum,
         stockNum: type.stockNum,
         price: (type.price/100).toFixed(2),
         specId: type.specId,
       });
       arr.push(spec);



        // let type = goodSpecModelList[i];
        // for (let j = 0; j < type.goodSpecModelList.length; j++) {
        //   let spec = type.goodSpecModelList[j];
        //   spec.goodsName = goodsName;
        //   spec.typeName = type.specName;
        //   spec.stockNum = stockNum;
        //   this.setSpec.push({
        //     killPrice: spec.killPrice / 100,
        //     killNum: spec.killNum,
        //     stockNum: spec.stockNum,
        //     price: spec.price,
        //     specId: spec.specId,
        //   });
        //   arr.push(spec);
        // }
      }
     this.specList = arr;
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
