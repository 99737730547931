<template>
  <div>
    <commonTable
      :tableData="tableData"
      :loading="loading"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      :currentPage="currentPage"
      :total="total"
    >
      <template v-slot:table>
        <el-table-column align="center" width="70">
          <template slot-scope="scope">
            <el-radio v-model="currentRowId" :label="scope.row.goodsId" @change="handleSelectionChange(scope.row)" >
              {{ ' ' }}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsId"
          align="center"
          label="产品ID"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column align="center" prop="goodsTypeName" label="分类" />
        <el-table-column prop="price" align="center" label="价格">
          <template slot-scope="scope">
            {{ (scope.row.price / 100).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column prop="dealNum" width="100" align="center" label="总销量" />
        <el-table-column align="center" label="发布时间">
          <template slot-scope="scope">
            {{ setGetDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import { getGoodsInfoPC } from "@/api/marketingActivities";
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
export default {
  data() {
    return {
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      currentRowId:'',
      multipleSelection: {}
    };
  },
  components: {
    commonTable,
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  created() {
    this.getGoodsInfoPC();
  },
  methods: {
    async getGoodsInfoPC() {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
       try {
        this.loading = true;
        const result = await getGoodsInfoPC(data);
        this.loading = false;
        const { pageInfo } = result.data;
        const { total, list } = JSON.parse(pageInfo);
        console.log(JSON.parse(JSON.stringify(list)))
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        console.log(error)
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGoodsInfoPC();
    },
    //当前页变更
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGoodsInfoPC();
    },
    handleSelectionChange(val) {
      this.currentRowId = val.goodsId;
      this.multipleSelection = val;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
